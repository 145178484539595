<template>
  <v-card outlined elevation="0" class="" rounded="lg">
    <v-card-title class="flex-column">
      <div class="text-h5 font-weight-bold align-self-start">Team</div>
    </v-card-title>
    <div class="d-flex flex-row pa-5">
      <v-text-field
        outlined
        v-model="search"
        :prepend-inner-icon="mdiMagnify"
        dense
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-dialog v-model="inviteDialog" width="550">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            text
            class="text-none text-body-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left color="primary" class="">
              {{ mdiCloudUpload }}
            </v-icon>
            Invite Admin
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 primary--text font-weight-bold">
            <v-row
              justify="center"
              align="center"
              class="justify-space-between"
            >
              <v-col cols="8"> Invite Admin </v-col>
              <v-btn @click="inviteDialog = false" icon>
                <v-icon x-large>
                  {{ mdiClose }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-form ref="emailAdmin">
              <v-row justify="center" align="center">
                <v-col cols="8" class="d-flex flex-column">
                  <label for="adminmail"> Email Address </label>
                  <v-text-field
                    v-model="adminToInvite"
                    placeholder="Enter email"
                    outlined
                    :rules="[
                      (v) => !!v || 'Required!',
                      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
                    ]"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="d-flex flex-row-reverse">
                  <v-btn
                    color="primary"
                    class="px-5 py-3 text-none text-body-1"
                    @click="validateInvAdmin"
                    :loading="inviting"
                  >
                    Send Invite
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-card-text class="pa-0 ma-0">
      <v-data-table
        :search="search"
        :headers="teamHeaders"
        :items="teamMembers"
        :loading="`${listAdminStatus}`"
      >
        <template v-slot:item.role="{ item }">
          <v-chip color="primary">
            {{ item.role }}
          </v-chip>
        </template>
        <template v-slot:item.rmaction="{ item }">
          <v-btn
            v-if="item.email !== currentUserEmail"
            icon
            @click="rmTeamMember(item)"
          >
            <v-icon color="danger">
              {{ mdiTrashCan }}
            </v-icon>
          </v-btn>
          <SmallConfirmation ref="delconfirm" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { mdiTrashCan, mdiMagnify, mdiCloudUpload, mdiClose } from "@mdi/js";
import SmallConfirmation from "@/components/assessment/SmallConfirmation.vue";
export default {
  props: {},
  data() {
    return {
      mdiTrashCan,
      mdiMagnify,
      mdiCloudUpload,
      mdiClose,
      search: "",
      adminToInvite: "",
      inviteDialog: false,
      inviting: false,
      teamMembers: [],
      teamHeaders: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Team Permission",
          value: "role",
        },
        {
          text: "Remove",
          value: "rmaction",
        },
      ],
    };
  },
  computed: {
    ...mapState("recruiter", ["admins", "listAdminStatus"]),
    ...mapGetters("recruiter", ["adminRoles", "adminRolesPretty"]),
    ...mapGetters("customer", ["hiringPlanKeys", "prettyHiringPlans"]),
    ...mapState("customer", ["customer"]),
    hiringPlan() {
      return this.hiringPlanKeys[this.customer?.getHiringPlan()];
    },
    hasPremium() {
      return this.hiringPlan === "SCALE";
    },
    companyDomain() {
      return this.customer?.getCompanyDomain();
    },
    currentUserEmail() {
      return this.$store.state?.user?.user?.email;
    },
  },
  components: { SmallConfirmation },
  methods: {
    ...mapActions("recruiter", [
      "inviteAdmin",
      "deleteAdmin",
      "listCustomerAdmins",
    ]),
    async rmTeamMember(member) {
      if (member.email === this.currentUserEmail) {
        this.$store.dispatch("notifs/addNotif", {
          text: `You cannot delete yourself!`,
          type: "error",
        });
        return;
      }
      if (
        await this.$refs.delconfirm.open(
          "Are you sure you want to delete this team member?"
        )
      ) {
        this.deleteAdmin({ email: member.email })
          .then((_) => {
            // this.$router.go(0); //reload
            this.teamMembers = [];
            this.populateAdmins();
            this.$store.dispatch("notifs/addNotif", {
              text: "Deleted Admin Successfully",
              type: "success",
            });
          })
          .catch((err) => {
            this.$store.dispatch("notifs/addNotif", {
              text:
                err.message || `Error occured, please try again after sometime`,
              type: "error",
            });
          });
      }
    },
    validateInvAdmin() {
      return this.$refs.emailAdmin.validate() && this.invite();
    },
    invite() {
      this.inviting = true;
      this.inviteAdmin({
        email: this.adminToInvite,
      })
        .then((r) => {
          this.adminToInvite = "";
          this.inviting = false;
          this.inviteDialog = false;
          this.$store.dispatch("notifs/addNotif", {
            text: "Invited Admin Successfully!",
            type: "success",
          });
          this.teamMembers = [];
          this.populateAdmins();
        })
        .catch((e) => {
          this.inviting = false;
          console.error(e);
          this.$store.dispatch("notifs/addNotif", {
            text:
              e.message || `Error occured, please try again after sometime!`,
            type: "error",
          });
        });
    },
    populateAdmins() {
      this.listCustomerAdmins().then((res) => {
        this.admins.getAdminsList().forEach((ad) => {
          const roleNum = this.adminRoles[ad.getRole()];
          this.teamMembers.push({
            name: ad.getName() || `${this.companyDomain} recruiter`,
            email: ad.getEmail(),
            role: this.adminRolesPretty[roleNum],
          });
        });
      });
    },
  },
  created() {
    this.populateAdmins();
    console.log(`c email`, this.currentUserEmail);
  },
};
</script>
<style scoped></style>
